.fullNavBar {
    font-family: 'VT323';
    font-size: 20px;
    padding-left: 15%;
    padding-right: 15%;
}

@media (max-width: 600px) {
    .fullNavBar {
        padding: 0;
        margin: 0;
    }
}