.bodyBody {
    background-color: rgb(36, 36, 36);
    background-size: cover;
    height: 100vh;
    width: 100vw;

}
.homeBody {
    background-image: url("../splash-art5.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    height: 100vh;
    width: 100vw;
    font-family: 'VT323';  
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.homeBody::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.fullNavBarHome {
    font-size: 20px;
    background-color: rgb(36, 36, 36);
    padding-left: 15%;
    padding-right: 15%;
}

.textBody {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 10%;
}

.textInfo {
    width: 30%;
    padding-right: 10%;
}

@media (max-width: 600px) {
    .fullNavBarHome {
        padding: 0;
        margin: 0;
    }
    .textInfo {
        width: 100%;
        padding-top: 30%;
        padding-left: 10%;
    }

    .linkedIn {
        padding-bottom: 10%;
    }
}

#introText {
    font-size: 20px;
}

.socials {
    padding-bottom: 5%;
    font-size: 30px;
    color: white;
}

.linkedIn, .instagram {
    color: white;
    text-align: center;
    align-items: center;
    align-content: center;
}

.linkedIn:hover, .instagram:hover {
    color: white;
}

img {
    color: white;
    display: block;
}

.note {
    font-size: 10px;
}